import {
  Grid,
  Image,
  Flex,
  Box,
  Text,
  TextInput,
  Checkbox,
  Textarea,
  Select,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { LuChevronDown } from "react-icons/lu";
import { FaComment, FaWallet, FaUserAlt } from "react-icons/fa";
import { startCase, camelCase } from "lodash";
import { useEffect } from "react";

const BeneficiaryCard = ({
  form,
  tokenApi,
  beneficiaryData,
  data,
  beneficiaryDataResponse,
  CardComponent,
  images,
  classes,
  getAge,
  ModalComponent,
  SelectItem,
}) => {
  const [opened, { open, close }] = useDisclosure();
  const aidData = JSON.parse(localStorage.getItem("aidBeneficiaryData"));
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (form.values.urgency) {
      open();
    }
  }, [form.values.urgency]);

  useEffect(() => {
    if (form.values.withAccompanying) {
      form.setFieldValue("accompanyingLiquidate", true);
    } else {
      form.setFieldValue("accompanyingLiquidate", false);
    }
  }, [form.values.withAccompanying]);

  const validateChecked =
    getAge(aidData?.userSelected?.fechaNac) < 18 ||
    getAge(aidData?.userSelected?.fechaNac) >= 65;

  return (
    <>
      <ModalComponent opened={opened} onClose={close} title="Alerta">
        <Text>
          {`Usted ha marcado el campo `}
          <Text span fw={700}>
            Urgencia
          </Text>
          {` ahora no podrá seleccionar: Vuelos, Hoteles ni transporte terrestre. Solo se habilitará el icono de ambulancia.`}
        </Text>
      </ModalComponent>
      <CardComponent
        title="Información del "
        subtitle="Beneficiario"
        visible={false}
      >
        <Grid align="center">
          <Grid.Col span={12}>
            <Flex align="center" gap={12}>
              <Image
                maw={100}
                radius="50%"
                style={{
                  border: "3px solid #cbd300",
                  borderRadius: "50%",
                }}
                alt="User image"
                src={images.avatar}
              />
              <Box>
                <Text fz="sm" color="white">
                  Titular:
                </Text>
                <Text fz="sm" fw={700} color="white">
                  {startCase(
                    camelCase(
                      `${beneficiaryData?.holder?.nombres} ${beneficiaryData?.holder?.apellidos}`
                    )
                  )}
                </Text>
                <Text fz="sm" fw={500} color="#cbd300">
                  {`${beneficiaryData?.holder?.descFuncionario} / ${beneficiaryData?.holder?.descNomina}`}
                </Text>
              </Box>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              classNames={{
                input: classes.input,
                label: classes.label,
              }}
              value={startCase(
                camelCase(
                  `${beneficiaryData?.userSelected?.nombres} ${beneficiaryData?.userSelected?.apellidos}`
                )
              )}
              readOnly
              label="Nombre del beneficiario"
              placeholder="Ingrese el nombre del beneficiario"
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox
              {...form.getInputProps("withAccompanying")}
              label="Con acompañante"
              disabled={validateChecked}
              checked={form.values.withAccompanying}
              className={classes.checkBox}
              sx={{
                label: {
                  color: "white",
                  fontWeight: "bold",
                },
                margin: 0,
              }}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Select
              {...form.getInputProps("budget")}
              searchable
              data={data?.aidBudget ?? []}
              label="Información presupuestal"
              placeholder="Elemento presupuestal"
              itemComponent={(props) => (
                <SelectItem {...props} formValue={form.values.budget} />
              )}
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
                item: classes.item,
              }}
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              icon={
                <FaWallet
                  size="1rem"
                  color={form.isDirty("budget") && "#004236"}
                />
              }
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Select
              {...form.getInputProps("approver")}
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
                item: classes.item,
              }}
              label="Aprobador"
              placeholder="Seleccione aprobador"
              data={data?.aidApprovers ?? []}
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              icon={
                <FaUserAlt
                  size="1rem"
                  color={form.isDirty("approver") && "#004236"}
                />
              }
              disabled={form?.values?.budget?.length === 0}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <TextInput
              {...form.getInputProps("referenceNumber")}
              classNames={{
                input: classes.input,
                label: classes.label,
              }}
              label="Número de referencia"
              placeholder="Ingrese el número de referencia"
              disabled={
                !form.isDirty("approver") ||
                userData?.datosUsuario?.permisosUnicos?.includes(
                  "auxilios Cenit"
                )
              }
              withAsterisk
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Textarea
              {...form.getInputProps("objective")}
              icon={
                <FaComment
                  size="1rem"
                  color={form.isDirty("objective") && "#004236"}
                />
              }
              placeholder="Objetivo del auxilio ambulatorio. Por favor, ingrese una descripción superior a 5 caracteres."
              label="Objetivo del auxilio ambulatorio"
              classNames={{
                input: classes.input,
                label: classes.label,
              }}
              minRows={4}
              maxRows={6}
              sx={{
                ".mantine-Textarea-icon": {
                  top: "-66px",
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col xs={12} md="content">
                <Checkbox
                  {...form.getInputProps("urgency")}
                  checked={form.values.urgency}
                  label="Urgencia"
                  className={classes.checkBox}
                  sx={{
                    label: {
                      color: "white",
                      fontWeight: "bold",
                    },
                    margin: 0,
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} md="content">
                <Checkbox
                  {...form.getInputProps("aidWithoutBeneficiary")}
                  label="Auxilio sin beneficiario"
                  checked={form.values.aidWithoutBeneficiary}
                  className={classes.checkBox}
                  sx={{
                    label: {
                      color: "white",
                      fontWeight: "bold",
                    },
                    margin: 0,
                  }}
                  disabled={!form.values.withAccompanying}
                />
              </Grid.Col>
              <Grid.Col xs={12} md="content">
                <Checkbox
                  {...form.getInputProps("accompanyingLiquidate")}
                  checked={form.values.accompanyingLiquidate}
                  label="Liquida acompañante"
                  className={classes.checkBox}
                  sx={{
                    label: {
                      color: "white",
                      fontWeight: "bold",
                    },
                    margin: 0,
                  }}
                  disabled={!form.values.withAccompanying}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </CardComponent>
    </>
  );
};

export default BeneficiaryCard;
