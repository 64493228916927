import {
  Grid,
  Flex,
  Text,
  NumberInput,
  Textarea,
  Button,
  Center,
  Select,
  Checkbox,
  LoadingOverlay,
  Divider,
} from "@mantine/core";
import { FaMapMarkerAlt, FaComment } from "react-icons/fa";
import { LuChevronDown } from "react-icons/lu";
import { BiSolidPlusCircle, BiSolidMinusCircle } from "react-icons/bi";
import { FaPlus, FaCalendarDays } from "react-icons/fa6";
import { DatePickerInput } from "@mantine/dates";

const AidCard = ({
  form,
  isValid,
  data,
  modalContent,
  loading,
  utils,
  isOpen,
  setIsOpen,
  ModalComponent,
  PaperComponent,
  CardComponent,
  opened,
  close,
  classes,
  useWindowSize,
  handleSubmit,
  outpatientcareServicesList,
  inputRef,
  setTravelExpensesSelected,
  travelExpensesSelected,
  selected,
  servicesData,
  toggleSelected,
  submitValidation,
  excludeBeneficiaryDates,
  excludeAcompanionDates,
  errorModal,
  handleAddTravelExpensesType,
  handleOpen,
  filterServices,
  openedBeneficiary,
  closeBeneficiary,
  validateBeneficiaryDates,
}) => {
  const isMd = useWindowSize("md");

  const isToday = (date) => {
    const today = new Date();
    if (!date) return false;
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
        data-testid="loading-overlay"
      />
    );
  }

  return (
    <>
      <ModalComponent
        opened={opened}
        onClose={close}
        title="Alerta"
        closeOnClickOutside={modalContent === "PENA" ? false : true}
      >
        <Flex direction="column" pb={12} gap={12}>
          {modalContent === "PENA" && (
            <>
              <Text color="#004236">
                Usted está enviando un paciente por fuera del
                <Text span fw={700} inherit>
                  {` Plan Estructurado de Niveles de Atención`}
                </Text>
                (PENA). Justifique su motivo.
              </Text>
              <Textarea
                {...form.getInputProps("reasonPENA")}
                label="Motivo"
                placeholder="Ingrese el motivo"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                minRows={4}
                maxRows={6}
              />
              <Button
                className={classes.button}
                onClick={close}
                disabled={!form.isValid("reasonPENA")}
              >
                Aceptar
              </Button>
            </>
          )}
          {!errorModal?.liquidator?.message?.length &&
            modalContent === "TRAVEL" && (
              <>
                <Text color="#004236" fw={700} ta="center">
                  Importante
                </Text>
                <Divider />
                <Text color="#004236" fw={700} ta="center">
                  Recuerde que al seleccionar algún servicio de viajes
                  (excluyendo ambulancias), se tomarán las fechas del
                  beneficiario para la búsqueda de reservas, en caso de ser una
                  urgencia, se tomará la fecha del acompañante en caso de
                  tenerlo.
                </Text>
                <Divider />

                <Flex gap={12} align="center" justify="flex-end" mt={12}>
                  <Button className={classes.buttonOutline} onClick={close}>
                    Volver a editar fechas
                  </Button>
                  <Button
                    className={classes.button}
                    data-testid="button-modal"
                    onClick={() => {
                      handleSubmit();
                      close();
                    }}
                  >
                    Aceptar
                  </Button>
                </Flex>
              </>
            )}
          {!!errorModal.liquidator.message.length && (
            <Text color="#004236" fw={700} ta="center">
              {errorModal.liquidator.message}
            </Text>
          )}
        </Flex>
      </ModalComponent>
      <ModalComponent
        title="Alerta"
        opened={openedBeneficiary}
        onClose={closeBeneficiary}
      >
        <Text color="#004236">{validateBeneficiaryDates.message}</Text>
      </ModalComponent>
      <CardComponent
        title="Información del "
        subtitle="auxilio ambulatorio"
        disabled={isValid}
        isAid
      >
        <Grid>
          <Grid.Col xs={12} md={6}>
            <DatePickerInput
              {...form.getInputProps("beneficiaryDates")}
              locale={utils.es}
              minDate={
                form.values.urgency &&
                form.values.accompanyingDates[0] &&
                new Date(form.values.accompanyingDates[0])
              }
              clearable
              excludeDate={excludeBeneficiaryDates}
              allowSingleDateInRange
              type="range"
              icon={
                <FaCalendarDays
                  size="1rem"
                  color={form.isDirty("beneficiaryDates") && "#004236"}
                />
              }
              rightSection={
                !form.values.beneficiaryDates.every(Boolean) ? (
                  <LuChevronDown size="1rem" color="#004236" />
                ) : null
              }
              valueFormat="DD/MMM/YY"
              classNames={{
                label: classes.label,
                input: classes.input,
                day: classes.calendar,
              }}
              label="Fechas del beneficiario"
              placeholder="Selecciones fechas del beneficiario"
              disabled={
                form.values.withAccompanying &&
                form.values.accompanyingDates[1] === null
                  ? form.values.urgency === true
                  : false
              }
            />
            <Checkbox
              checked={form.values.isReturnBeneficiary}
              onChange={(e) =>
                form.setFieldValue(
                  "isReturnBeneficiary",
                  e.currentTarget.checked
                )
              }
              label="Es retorno"
              className={classes.checkBox}
              sx={{
                label: {
                  color: "white",
                  fontWeight: "bold",
                },
                marginTop: "8px",
                marginBottom: 0,
              }}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <DatePickerInput
              {...form.getInputProps("accompanyingDates")}
              minDate={
                form.isTouched("beneficiaryDates") &&
                form.values.beneficiaryDates.every(
                  (fecha) => !isToday(fecha)
                ) &&
                form.values.beneficiaryDates[0] &&
                new Date(form.values.beneficiaryDates[0])
              }
              clearable
              locale={utils.es}
              type="range"
              icon={
                <FaCalendarDays
                  size="1rem"
                  color={form.isDirty("accompanyingDates") && "#004236"}
                />
              }
              allowSingleDateInRange
              rightSection={
                !form.values.accompanyingDates.every(Boolean) ? (
                  <LuChevronDown size="1rem" color="#004236" />
                ) : null
              }
              valueFormat="DD/MMM/YY"
              excludeDate={excludeAcompanionDates}
              classNames={{
                label: classes.label,
                input: classes.input,
                day: classes.calendar,
              }}
              label="Fechas del acompañante"
              placeholder="Selecciones fechas del acompañante"
              disabled={
                !form.values.withAccompanying ||
                (!form.values.urgency &&
                  form.values.beneficiaryDates[0] === null)
              }
            />
            <Checkbox
              checked={form.values.isReturnAccompanying}
              onChange={(e) =>
                form.setFieldValue(
                  "isReturnAccompanying",
                  e.currentTarget.checked
                )
              }
              label="Es retorno"
              className={classes.checkBox}
              sx={{
                label: {
                  color: "white",
                  fontWeight: "bold",
                },
                marginTop: "8px",
                marginBottom: 0,
              }}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Select
              {...form.getInputProps("originCity")}
              icon={
                <FaMapMarkerAlt
                  size="1rem"
                  color={form.isDirty("originCity") && "#004236"}
                />
              }
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              searchable
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
                item: classes.item,
              }}
              data={data.cities}
              label="Ciudad origen"
              placeholder="Ciudad origen"
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Select
              {...form.getInputProps("destinationCity")}
              ref={inputRef}
              icon={
                <FaMapMarkerAlt
                  size="1rem"
                  color={form.isDirty("destinationCity") && "#004236"}
                />
              }
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              searchable
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
                item: classes.item,
              }}
              data={data.cities}
              label="Ciudad destino"
              placeholder="Ciudad destino"
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Flex justify="space-between" align="center">
              <Text size="sm" c="white" sx={{ padding: "1rem 0" }}>
                <Text
                  span
                  c="#cbd300"
                  inherit
                  sx={(theme) => ({
                    [theme.fn.smallerThan("md")]: {
                      display: "block",
                      fontSize: "1.25rem",
                    },
                  })}
                  mr={4}
                >
                  Servicios de viajes
                </Text>
                <Text span>seleccione los servicios que desea utilizar</Text>
              </Text>
              {!isOpen ? (
                <BiSolidPlusCircle
                  size="1.25rem"
                  style={{
                    flexShrink: 0,
                    color: "#cbd300",
                    display: !isMd && "none",
                  }}
                  onClick={handleOpen}
                />
              ) : (
                <BiSolidMinusCircle
                  size="1.25rem"
                  style={{
                    flexShrink: 0,
                    color: "#cbd300",
                    display: !isMd && "none",
                  }}
                  onClick={handleOpen}
                />
              )}
            </Flex>
            <Text size="sm" c="white" pb="1rem">
              <Text
                span
                c="#cbd300"
                inherit
                sx={(theme) => ({
                  [theme.fn.smallerThan("md")]: {
                    display: "block",
                    fontSize: "1.25rem",
                  },
                })}
                mr={4}
              >
                Nota:
              </Text>
              <Text span>
                Los servicios de viajes son pagados directamente a los
                prestadores de servicios.
              </Text>
            </Text>

            {(isOpen || !isMd) && (
              <Grid columns={filterServices.length}>
                {filterServices?.map((item, index) => (
                  <Grid.Col xs={filterServices.length} md={1} key={index}>
                    <PaperComponent
                      disabled={!form.isDirty("destinationCity")}
                      title={item.title}
                      clicked={selected
                        .some((sel) => sel.alias === item.alias)
                        .toString()}
                      icon={item.icon}
                      data-testid="toggle-button"
                      onClick={() => toggleSelected(item)}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            )}
          </Grid.Col>
          <Grid.Col span={12}>
            <Grid align="flex-end">
              <Grid.Col xs={12} md={7}>
                <Select
                  {...form.getInputProps("travelExpenses.name")}
                  label="Otros Gastos"
                  description="Recuerde seleccionar el otro gasto, ingresar el valor y dar clic en el botón Agregar"
                  transitionProps={{ transition: "pop" }}
                  placeholder="Otros gastos"
                  rightSection={<LuChevronDown size="1rem" color="#004236" />}
                  classNames={{
                    dropdown: classes.dropdown,
                    rightSection: classes.rightSection,
                    label: classes.label,
                    input: classes.input,
                    item: classes.item,
                  }}
                  data={data.travelExpenses}
                  sx={{
                    ".mantine-InputWrapper-description": {
                      color: "#eaeaea",
                      fontSize: "13px",
                    },
                    width: "100%",
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} md="auto">
                <NumberInput
                  {...form.getInputProps("travelExpenses.value")}
                  parser={utils.parserNumberInput}
                  formatter={utils.formatterNumberInput}
                  min={0}
                  label="Valor"
                  placeholder="Monto"
                  sx={{ width: "100%" }}
                  classNames={{
                    input: classes.input,
                    label: classes.label,
                  }}
                  disabled={!form.isDirty("travelExpenses.name")}
                />
              </Grid.Col>
              <Grid.Col xs={12} md="content">
                <Button
                  className={classes.button}
                  fullWidth
                  disabled={!form.isDirty("travelExpenses.value")}
                  data-testid="add-button"
                  onClick={handleAddTravelExpensesType}
                >
                  Agregar
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          {travelExpensesSelected.length > 0 && (
            <Grid.Col span={12} mt={16}>
              {travelExpensesSelected.map((item, index) => (
                <Flex
                  key={index}
                  align="center"
                  justify="space-between"
                  mt={8}
                  py={10}
                  px={8}
                  bg="white"
                  sx={{
                    borderRadius: "5px",
                  }}
                  fz={14}
                >
                  <Text
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {item.name}
                  </Text>
                  <Text ml="auto">{utils.formatter.format(item.value)}</Text>
                  <FaPlus
                    size={24}
                    style={{
                      transform: "rotate(45deg)",
                      cursor: "pointer",
                      marginLeft: "12px",
                    }}
                    data-testid="plus-button"
                    onClick={() =>
                      setTravelExpensesSelected(
                        travelExpensesSelected.filter((_, i) => i !== index)
                      )
                    }
                  />
                </Flex>
              ))}
              <Flex
                align="center"
                justify="space-between"
                mt={8}
                py={10}
                px={8}
                bg="#cbd300"
                sx={{
                  borderRadius: "5px",
                }}
              >
                <Text fw={700} color="#004236">
                  Total
                </Text>
                <Text fw={700} color="#004236">
                  {utils.currenciesSum(
                    travelExpensesSelected.map((item) => item.value)
                  )}
                </Text>
              </Flex>
            </Grid.Col>
          )}
          <Grid.Col span={12}>
            <Textarea
              {...form.getInputProps("clinicalConditions")}
              icon={
                <FaComment
                  size="1rem"
                  color={form.isDirty("clinicalConditions") && "#004236"}
                />
              }
              placeholder="Condiciones clínicas"
              label="Condiciones clínicas"
              classNames={{
                input: classes.input,
                label: classes.label,
              }}
              minRows={4}
              maxRows={6}
              sx={{
                ".mantine-Textarea-icon": {
                  top: "-66px",
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Center>
              <Button
                w={200}
                className={classes.button}
                onClick={submitValidation}
                disabled={
                  !form.isDirty("originCity") ||
                  !form.isDirty("destinationCity") ||
                  form.values.beneficiaryDates.includes(null) ||
                  (form.values.withAccompanying &&
                    form.values.accompanyingDates.includes(null)) ||
                  validateBeneficiaryDates.state ||
                  !form.isValid("clinicalConditions")
                }
              >
                Continuar
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      </CardComponent>
    </>
  );
};

export default AidCard;
